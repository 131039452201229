import classNames from "classnames";
import { ChangeEvent, ReactNode } from "react";

import CheckIconSmall from "../icons/CheckIconSmall";
import { DataAttributes, Spacing, SpacingProps, ThemeColorBg } from "../types";
import { getSpacingClass } from "../utils";

interface CheckboxProps extends SpacingProps {
  checked: boolean;
  onChange: (checked: boolean, event?: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  labelComponent?: JSX.Element;
  inputClassName?: string;
  disabled?: boolean;
  inputSpacing?: Spacing;
  bgColor?: ThemeColorBg;
  UNSAFE_className?: string;
  stopPropagationOnClick?: boolean;
  ariaLabel?: string;
  dataAttributes?: DataAttributes;
  isError?: boolean;
}

export function Checkbox({
  checked,
  label,
  onChange,
  inputClassName,
  disabled,
  labelComponent,
  spacing,
  inputSpacing,
  bgColor,
  UNSAFE_className,
  stopPropagationOnClick,
  ariaLabel,
  dataAttributes,
  isError,
}: CheckboxProps) {
  return (
    <label
      className={classNames(
        "flex group/checkbox",
        getSpacingClass(spacing),
        bgColor,
        UNSAFE_className,
        {
          "cursor-not-allowed": disabled,
          "cursor-pointer": !disabled,
        }
      )}
      onClick={(e) => stopPropagationOnClick && e.stopPropagation()}
      data-cy={dataAttributes?.cy || "checkbox"}
      data-testid={dataAttributes?.testid || "checkbox"}
    >
      <div className={classNames("relative flex h-4", getSpacingClass(inputSpacing))}>
        <input
          onChange={(e) => onChange(e.target.checked, e)}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          aria-label={ariaLabel}
          className={classNames(
            "peer border-blueGray300 bg-white h-4 w-4 rounded-sm border align-text-bottom appearance-none focus-visible:outline-none",
            inputClassName,
            {
              "cursor-not-allowed bg-blueGray25": disabled,
              "cursor-pointer": !disabled,
              "!border-red500": isError && !checked,
            },
            // Hover
            {
              "group-hover/checkbox:border-blueGray900": !disabled,
            },
            // Focus
            "focus:ring-0",
            "focus-visible:ring-1 focus-visible:ring-blueGray300 focus-visible:border-blueGray500",
            // Active
            {
              "active:bg-blueGray25": !disabled,
            },
            // Checked
            {
              "checked:bg-white checked:border-primaryBlue500 group-hover/checkbox:checked:border-primaryBlue500 checked:ring-0":
                !disabled,
            },
            // Checked hover
            {
              "checked:hover:ring-0": !disabled,
            },
            // Checked focus
            "focus:checked:ring-0",
            "focus-visible:checked:ring-1 focus-visible:checked:ring-primaryBlue500",
            // Checked active
            {
              "checked:active:bg-primaryBlue500 checked:active:border-primaryBlue700 group-hover/checkbox:checked:active:border-primaryBlue700 checked:active:ring-0":
                !disabled,
            }
          )}
        />
        <CheckIconSmall
          size={10}
          className={classNames(
            // Shared
            "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  opacity-0 pointer-events-none",
            // Hover
            {
              "group-hover/checkbox:opacity-100 group-hover/checkbox:text-blueGray300": !disabled,
            },
            // Focus - none
            // Pressed
            {
              "peer-active:text-blueGray900": !disabled,
            },
            // Checked
            "peer-checked:opacity-100",
            {
              "peer-checked:text-primaryBlue500": !disabled,
              "peer-checked:text-blueGray300": disabled,
            },
            // Checked hover
            {
              "group-hover/checkbox:peer-checked:text-primaryBlue500": !disabled,
            },
            // Checked focus - none
            // Checked pressed
            {
              "peer-checked:peer-active:text-white group-hover/checkbox:peer-checked:peer-active:text-white":
                !disabled,
            }
          )}
        />
      </div>
      {label && !labelComponent && (
        <span className="text-primaryBlue900 -mt-0.75 ml-2 pt-0.5 text-sm">{label}</span>
      )}
      {labelComponent && labelComponent}
    </label>
  );
}
