import classNames from "classnames";
import { ComponentType, HTMLAttributes, ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { IconProps, InfoIcon } from "../icons";

type Variant = "WHITE" | "GRAY";
const VARIANT_MAPS: Record<Variant, string> = {
  WHITE: "bg-white ",
  GRAY: "bg-blueGray10 text-blueGray600",
};

export interface TooltipProps {
  children?: ReactNode;
  body: ReactNode;
  iconSize?: number;
  icon?: ComponentType<IconProps>;
  trigger?: "onClick" | "onHover";
  position?: "bottom" | "bottom-start" | "bottom-end" | "top" | "top-end" | "top-start";
  containerProps?: HTMLAttributes<HTMLDivElement>;
  widthClassName?: string;
  variant?: Variant;
  onTooltipOpen?: () => void;
}

const DefaultChildren = ({
  iconSize = 12,
  icon: TooltipIcon = InfoIcon,
}: {
  iconSize?: number;
  icon?: ComponentType<IconProps>;
}) => {
  return (
    <div>
      <span className="sr-only">
        <FormattedMessage defaultMessage="More info" id="HOsSgX" />
      </span>
      <TooltipIcon size={iconSize} className="text-blueGray500 cursor-pointer fill-current" />
    </div>
  );
};

// Render the tooltip with tailwindcss styling
const Tooltip = ({
  children,
  body,
  iconSize,
  icon,
  position = "bottom",
  variant = "WHITE",
  containerProps = {},
  widthClassName = "w-64",
  onTooltipOpen,
}: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const { className, ...otherContainerProps } = containerProps;

  return (
    <div
      className={classNames("group relative", className)}
      onMouseEnter={() => {
        onTooltipOpen?.();
        setOpen(true);
      }}
      onMouseLeave={() => setOpen(false)}
      {...otherContainerProps}
    >
      <div>{children || <DefaultChildren iconSize={iconSize} icon={icon} />}</div>

      <div
        className={classNames(
          "absolute top-7 left-0 z-50 flex max-w-64 whitespace-pre-wrap break-normal rounded py-1 px-2 shadow",
          VARIANT_MAPS[variant],
          widthClassName,
          {
            hidden: !open,
            "-translate-x-1/2": position === "bottom",
            "-translate-x-full": position === "bottom-end",
            "-translate-x-1/2 -translate-y-20": position === "top",
            "-translate-x-full -translate-y-20": position === "top-end",
            "-translate-y-20": position === "top-start",
          }
        )}
      >
        <div className="block w-full">{body}</div>
      </div>
    </div>
  );
};

export default Tooltip;
