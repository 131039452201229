import classNames from "classnames";
import Image from "next/image";
import { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  SortingRule,
  TableCellProps,
  TableRowProps,
  useExpanded,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { Button } from "../button";
import { Checkbox } from "../checkbox";
import { LongArrowUpIcon, RefreshIcon } from "../icons";
import { DataAttributes, ThemeColorBg, ThemeColorText } from "../types";
import { convertDataAttributes } from "../utils";
import emptyTableResultsImage from "./assets/empty-table-results.svg";
import errorTableResultsImage from "./assets/error-table-results.svg";
import styles from "./Table.module.scss";
import Tooltip from "./Tooltip";

type CustomRowBgColor = "LIGHT";

// eslint-disable-next-line no-restricted-syntax
export enum ColumnAlign {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}

// extending 3rd party library typings
// eslint-disable-next-line @typescript-eslint/ban-types
export type TableColumn<Data extends object = {}> = Column<Data>;

type HeaderVariant = "DEFAULT" | "LIGHT" | "DARK";

const HEADER_MAP: Record<HeaderVariant, `${ThemeColorBg} ${ThemeColorText}`> = {
  DEFAULT: "bg-blueGray25 text-blueGray600",
  LIGHT: "bg-white text-blueGray600",
  DARK: "bg-blueGray800 text-blueGray100",
};

interface CellWrapperProps {
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<Data extends object = {}> {
  columns: ReadonlyArray<TableColumn<Data>>;
  data?: Array<Data>;
  className?: string;
  header?: HeaderVariant;
  loading?: boolean;
  loadingRowLimit?: number;
  error?: boolean;
  emptyMessage?: ReactNode;
  emptyImage?: ReactNode;
  emptyHeightAsRow?: boolean;
  initialSort?: SortingRule<string>;
  getColumnProps?: (data: ColumnInstance<Data>) => Partial<TableCellProps>;
  getHeaderProps?: (userProps?: any) => void;
  getRowProps?: (row: Row<Data>) => Partial<TableRowProps | DataAttributes>;
  getCellProps?: (cell: Cell<Data>) => Partial<TableCellProps & { colspan?: number }>;
  getCellWrapperProps?: (cell: Cell<Data>) => Partial<CellWrapperProps>;
  onRowClick?: (row: Data) => void;
  omitRowClick?: (row: Data) => boolean;
  onSort?: (rules: Array<SortingRule<string>>) => void;
  onColumnTooltipOpen?: (column: TableColumn<Data>) => void;
  onRetry?: () => void;
  onGoBack?: () => void;
  selectable?: boolean;
  biColor?: boolean;
  customBiColor?: string;
  customRowBgColor?: CustomRowBgColor;
  borderClassName?: string;
  dataAttributes?: DataAttributes;
  flexLayout?: boolean;
  getRowSeparator?: (row: Row<Data>, index: number) => ReactNode;
  expandableRows?: boolean;
  getSubRows?: (row: Data, index: number) => Data[];
  expandedKey?: string;
  expandRowOnClick?: (row: Data) => boolean;
  headerGroupClassName?: string;
  stickyFirstColumn?: boolean;
  narrowRows?: boolean;
  tightColumns?: boolean;
  customErrorComponent?: ReactNode;
}

function TableEmpty({
  message = <FormattedMessage defaultMessage="No results" id="jHJmjf" />,
  image,
  heightAsRow = false,
}: {
  message?: ReactNode;
  image?: ReactNode;
  heightAsRow?: boolean;
}) {
  const intl = useIntl();

  return (
    <div
      className="mx-auto flex w-full grow flex-col items-center justify-center"
      style={{
        // this height calc allow to perfectly align table size (and empty message)
        minHeight: heightAsRow ? "46px" : "calc(100vh - 300px)",
        background: heightAsRow ? "white" : "transparent",
      }}
    >
      {image ? (
        image
      ) : (
        <Image
          src={emptyTableResultsImage}
          alt={intl.formatMessage({ defaultMessage: "Empty Table Results", id: "v4OFy/" })}
          className="h-[90px] max-w-full"
          width={118}
          height={90}
        />
      )}
      <div
        className={classNames("text-center text-sm font-normal text-blueGray600", {
          "mt-6": !heightAsRow,
        })}
      >
        {message}
      </div>
    </div>
  );
}

function TableError({ onRetry, onGoBack }: { onRetry?: () => void; onGoBack?: () => void }) {
  const intl = useIntl();

  return (
    <div className="mx-auto mt-10 flex grow flex-col items-center justify-center">
      <Image
        src={errorTableResultsImage}
        alt={intl.formatMessage({ defaultMessage: "Table Results Error", id: "Ze5N5L" })}
        className="h-[94px]"
        width={84}
        height={94}
      />
      <div className="text-blueGray600 mt-6 w-80 text-center text-sm font-normal">
        <FormattedMessage
          defaultMessage="Couldn’t retrieve data for this query. We have been notified of the issue and will be addressing it shortly. "
          id="8bm3E0"
        />
        {onRetry && (
          <div className="my-6 flex justify-center">
            <Button leftIcon={RefreshIcon} onClick={onRetry}>
              <FormattedMessage defaultMessage="Try Again" id="jsy7pk" />
            </Button>
          </div>
        )}

        {onGoBack && (
          <div className="flex justify-center">
            <Button variant="LINK" size="REGULAR" onClick={onGoBack}>
              <FormattedMessage defaultMessage="Go Back" id="ekfOaV" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
function TableLoading<Data extends object = {}>({
  loadingRowLimit,
  headerGroups,
  narrowRows,
}: {
  loadingRowLimit?: number;
  headerGroups: HeaderGroup<Data>[];
  narrowRows?: boolean;
}) {
  return (
    <tbody>
      {[...new Array(loadingRowLimit)].map(() =>
        headerGroups.map((headerGroup, i) => (
          <tr key={`loader-${i}`} className="border-blueGray200 border-b">
            {headerGroup.headers.map((column) => (
              // eslint-disable-next-line react/jsx-key
              <td
                {...column.getHeaderProps()}
                className={typeof column.className === "string" ? column.className : undefined}
              >
                <div
                  className={classNames("bg-blueGray200 h-3 w-full animate-pulse", {
                    "my-5": !narrowRows,
                    "mt-[10px] my-[9px]": narrowRows,
                  })}
                />
              </td>
            ))}
          </tr>
        ))
      )}
    </tbody>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TableCell = <Data extends object = {}>({
  cell,
  wrapperProps,
}: {
  cell: Cell<Data>;
  wrapperProps?: CellWrapperProps;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const [isTruncated, setIsTruncated] = useState(false);
  const noWrap =
    typeof cell.column.noWrap === "function"
      ? cell.column.noWrap(cell.row.original)
      : cell.column.noWrap;

  useEffect(() => {
    const checkIfTruncated = () => {
      const scrollWidth = ref?.current?.scrollWidth;
      const clientWidth = ref?.current?.clientWidth;

      if (typeof scrollWidth === "number" && typeof clientWidth === "number" && !noWrap) {
        const compare = scrollWidth > clientWidth;
        setIsTruncated(compare);
      }
    };

    checkIfTruncated();
    window.addEventListener("resize", checkIfTruncated);

    return () => window.removeEventListener("resize", checkIfTruncated);
  }, [ref, setIsTruncated, noWrap]);

  const onTogglePopoverVisibility = () => {
    if (popoverRef.current && !noWrap) {
      popoverRef.current.classList.toggle("hidden");
    }
  };

  return (
    <div
      className={classNames("relative whitespace-nowrap", {
        "select-none": isTruncated,
      })}
    >
      <div className="inline-block max-w-full align-middle" {...wrapperProps}>
        {typeof cell.column.renderIcon === "function" && cell.column.renderIcon(cell) ? (
          <div className="absolute -mt-0.5 mr-1 -ml-4">{cell.column.renderIcon(cell)}</div>
        ) : null}
        <div
          ref={ref}
          onMouseOver={onTogglePopoverVisibility}
          onMouseOut={onTogglePopoverVisibility}
          className={classNames({
            "max-w-full truncate": !noWrap,
          })}
        >
          {cell.render("Cell")}
          {isTruncated && (
            <div
              ref={popoverRef}
              className={`-mt-1.25 -ml-2.25 border-blueGray100 bg-blueGray10 shadow-1 absolute top-0 left-0 z-10 hidden
                cursor-text rounded-sm border px-2 pt-1 pb-0.5`}
              onClick={(e) => e.stopPropagation()}
            >
              <span className="select-all">{cell.render("Cell")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// extending 3rd party library typings
// eslint-disable-next-line @typescript-eslint/ban-types
export const Table = <Data extends object = {}>({
  columns,
  data = [],
  getColumnProps = () => ({}),
  getCellProps = () => ({}),
  getRowProps = () => ({}),
  loading = false,
  loadingRowLimit = 25,
  error = false,
  className = "",
  header = "DEFAULT",
  onRowClick,
  omitRowClick,
  onSort,
  initialSort,
  emptyMessage,
  emptyImage,
  emptyHeightAsRow,
  onRetry,
  onGoBack,
  selectable,
  customRowBgColor,
  biColor,
  customBiColor = "",
  borderClassName = "",
  dataAttributes = {},
  flexLayout,
  expandableRows,
  getRowSeparator,
  getSubRows = () => [],
  expandedKey = "expanded",
  expandRowOnClick = () => false,
  getCellWrapperProps = () => ({}),
  headerGroupClassName = "",
  stickyFirstColumn = false,
  narrowRows,
  tightColumns = false,
  customErrorComponent,
}: TableProps<Data>) => {
  const stickyHeaderRef = useRef<HTMLTableRowElement>(null);
  const [isHeaderSticked, setIsHeaderSticked] = useState(false);

  const isSortable = typeof onSort === "function";
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        ...(initialSort?.id && { sortBy: [initialSort] }),
      },
      manualSortBy: true,
      disableSortBy: !isSortable,
      getSubRows: getSubRows,
      manualExpandedKey: expandedKey,
    },
    useSortBy,
    ...(flexLayout ? [useFlexLayout] : []),
    ...(selectable ? [useRowSelect] : []),
    ...(expandableRows ? [useExpanded] : []),
    (hooks) => {
      if (!selectable) return null;

      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const { checked, onChange } = getToggleAllRowsSelectedProps();
            return (
              <Checkbox
                checked={Boolean(checked)}
                onChange={(_, event) => {
                  if (onChange && event) {
                    onChange(event);
                  }
                }}
                label=""
                disabled={!data || data?.length === 0}
              />
            );
          },
          maxWidth: 40,
          headClassName: "w-8",
          Cell: ({ row }) => {
            const { checked, onChange } = row.getToggleRowSelectedProps();
            return (
              <Checkbox
                checked={Boolean(checked)}
                onChange={(_, event) => {
                  if (onChange && event) {
                    onChange(event);
                  }
                }}
                label=""
                UNSAFE_className={classNames({
                  "invisible group-hover:visible": !checked,
                })}
              />
            );
          },
        } as ColumnInstance<Data>,
        ...columns,
      ]);

      return;
    }
  );

  useEffect(() => {
    isSortable && onSort?.(sortBy);
  }, [sortBy, isSortable]);

  useEffect(() => {
    // Cache ref so refs match when `unobserve` on unmount
    const cachedRef = stickyHeaderRef.current;

    const observer = new IntersectionObserver(
      ([e]) => setIsHeaderSticked(e.intersectionRatio < 1),
      { threshold: [1] }
    );

    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  return (
    <div
      {...convertDataAttributes(dataAttributes)}
      className={classNames("flex h-full flex-col", className, {
        "overflow-x-scroll": stickyFirstColumn,
      })}
      tabIndex={0}
    >
      <table className="relative w-full select-text break-all" {...getTableProps()}>
        <thead>
          {/* Use this <tr> with the Intersection Observer to determine if the header is sticky */}
          <tr className="h-0" ref={stickyHeaderRef} />
          {headerGroups.map((headerGroup) => (
            // `key` comes from `getHeaderGroupProps`
            // eslint-disable-next-line react/jsx-key
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={classNames(
                "sticky top-0 z-10 min-h-full transition-shadow duration-100",
                headerGroupClassName,
                {
                  "shadow-1": isHeaderSticked,
                  "h-8": narrowRows,
                  "h-10": !narrowRows,
                }
              )}
            >
              {headerGroup.headers.map((column, i, arr) => {
                const isLastColumn = i === headerGroup.headers.length - 1;

                return (
                  // `key` comes from `getHeaderProps`
                  // eslint-disable-next-line react/jsx-key
                  <th
                    // `react-table` documentation calls for `getSortByToggleProps` usage inside of
                    //  `getHeaderProps`, but the TS types are incorrect here, have to use `ts-ignore`
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: {
                        ...(column.size?.minWidth ? { minWidth: column.size.minWidth } : {}),
                        ...(column.size?.width ? { width: column.size.width } : {}),
                        ...(column.size?.maxWidth ? { maxWidth: column.size.maxWidth } : {}),
                      },
                    })}
                    className={classNames(
                      "group whitespace-nowrap text-left text-sm font-normal first:rounded-tl-md last:rounded-tr-md",
                      column.headClassName,
                      {
                        "pr-4": !tightColumns,
                        "pl-8": !tightColumns && typeof column.renderIcon === "function",
                        "pl-4": !tightColumns && typeof column.renderIcon !== "function",
                        "py-3": !narrowRows,
                        [HEADER_MAP[header as HeaderVariant]]: header,
                        "bg-blueGray50 text-blueGray600": header === "DEFAULT" && biColor,
                        [classNames(
                          "sticky left-0 z-5 bg-blueGray100",
                          styles.shadowRightOnlyHead
                        )]: i === 0 && stickyFirstColumn,
                      }
                    )}
                    title={undefined}
                  >
                    <div
                      className={classNames("flex items-center", {
                        "justify-end": column.align === ColumnAlign.RIGHT,
                        "justify-center": column.align === ColumnAlign.CENTER,
                        "-mr-5":
                          !isLastColumn && column.align === ColumnAlign.RIGHT && column.tooltipText,
                      })}
                    >
                      {column.canSort ? (
                        <span data-cy="table-column-sort" className="mr-1 -ml-4 pt-px">
                          <Tooltip
                            body={
                              column.isSorted && column.isSortedDesc
                                ? column.sortTooltip?.desc
                                : column.sortTooltip?.asc
                            }
                            {...(column.sortTooltipProps ? column.sortTooltipProps : {})}
                          >
                            <LongArrowUpIcon
                              className={classNames("-mt-1", {
                                "text-blueGray400 group-hover:text-blueGray500": !column.isSorted,
                                "text-blueGray700": column.isSorted,
                                "-scale-y-1": column.isSortedDesc,
                              })}
                            />
                          </Tooltip>
                        </span>
                      ) : null}
                      {column.render("Header")}
                      {column.tooltipText ? (
                        <div className="-mt-0.5 ml-2 pt-px text-center">
                          <Tooltip
                            position={i === arr.length - 1 ? "bottom-end" : "bottom"}
                            body={column.tooltipText}
                            {...(column.tooltipProps ? column.tooltipProps : {})}
                          />
                        </div>
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        {/* Loading state */}
        {loading && (
          <TableLoading
            loadingRowLimit={loadingRowLimit}
            headerGroups={headerGroups}
            narrowRows={narrowRows}
          />
        )}

        {/* Data state */}
        {!loading && !error && data.length > 0 && (
          <tbody data-cy="tbody" data-testid="tbody" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const separator = getRowSeparator ? getRowSeparator(row, i) : null;

              const rowSelectionClass = onRowClick ? "cursor-pointer" : "";
              return (
                // `key` comes from  `getRowProps`
                // eslint-disable-next-line react/jsx-key
                <Fragment key={row.getRowProps().key}>
                  {separator}
                  <tr
                    {...row.getRowProps()}
                    {...getRowProps(row)}
                    className={classNames("group", rowSelectionClass, {
                      "hover:bg-primaryBlue10": onRowClick,
                      "border-blueGray200 border-b": !biColor && !borderClassName,
                      "bg-blueGray10": (biColor && !customBiColor && i % 2 === 1) || row.depth == 1,
                      [customBiColor]: customBiColor && i % 2 === 1,
                      [borderClassName]: borderClassName,
                      "bg-white": customRowBgColor === "LIGHT" || stickyFirstColumn,
                      "shadow-0": row.isExpanded,
                    })}
                    onClick={() => {
                      if (expandableRows && expandRowOnClick && expandRowOnClick(row.original)) {
                        row.toggleRowExpanded();
                      } else {
                        onRowClick && !omitRowClick?.(row.original) && onRowClick(row.original);
                      }
                    }}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      const className =
                        typeof cell.column.className === "function"
                          ? cell.column.className?.(row.original)
                          : cell.column.className;
                      const noWrap =
                        typeof cell.column.noWrap === "function"
                          ? cell.column.noWrap(row.original)
                          : cell.column.noWrap;
                      return (
                        // `key` comes from  `getCellProps`
                        // eslint-disable-next-line react/jsx-key
                        <td
                          {...cell.getCellProps([
                            {
                              className: classNames("text-sm font-normal", className, {
                                "pr-4": !tightColumns,
                                "pl-8":
                                  !tightColumns && typeof cell.column.renderIcon === "function",
                                "pl-4":
                                  !tightColumns && typeof cell.column.renderIcon !== "function",
                                "py-4": !cell.column.clearPy && !narrowRows,
                                "py-1.5": narrowRows,
                                "text-right": cell.column.align === ColumnAlign.RIGHT,
                                "text-center": cell.column.align === ColumnAlign.CENTER,
                                "max-w-px": !noWrap,
                                [classNames(
                                  "sticky left-0 z-5 bg-inherit drop-shadow-right",
                                  styles.shadowRightOnly
                                )]: cellIndex === 0 && stickyFirstColumn,
                              }),
                              style: {
                                ...(cell.column.size?.minWidth
                                  ? { minWidth: cell.column.size.minWidth }
                                  : {}),
                                ...(cell.column.size?.width
                                  ? { width: cell.column.size.width }
                                  : {}),
                                ...(cell.column.size?.maxWidth
                                  ? { maxWidth: cell.column.size.maxWidth }
                                  : {}),
                              },
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          <TableCell cell={cell} wrapperProps={getCellWrapperProps(cell)} />
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        )}
      </table>
      {/* Empty state */}
      {!loading && !error && data.length < 1 && (
        <TableEmpty message={emptyMessage} image={emptyImage} heightAsRow={emptyHeightAsRow} />
      )}
      {/* Error state */}
      {error ? (
        customErrorComponent ? (
          customErrorComponent
        ) : (
          <TableError onRetry={onRetry} onGoBack={onGoBack} />
        )
      ) : null}
    </div>
  );
};

Table.columnAlign = ColumnAlign;
